// animation
(function () {
  var animation = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      if ( $(window).width() > 641 ) {
        $(document).on('mousewheel DOMMouseScroll', function(e) {
          var winScroll = $(window).scrollTop() + 450;
          var targetScroll = $('.section-benefit').offset().top - 450;
          if ( winScroll > targetScroll ) {
            $('.section-benefit').on("mousewheel DOMMouseScroll", function(e) {
              if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
                animation.showfeatures();
              } else {
                animation.showbenefit()
              }
            });
          }
        });
      }
      if ( $(window).width() < 641 ) {
        animation.clear;
      }
    },
    showbenefit: function () {
      $('.a-cont--features').removeClass('cont-up').addClass('cont-down');
      $('.a-cont--benefit').removeClass('cont-down').addClass('cont-up');

      $('.a-pic--benefit').removeClass('img-up').addClass('img-down');
      $('.a-pic--features').removeClass('img-down').addClass('img-up');

      $('.a-text--features').removeClass('hide-cont').addClass('show-cont');
      $('.a-text--benefit').removeClass('show-cont').addClass('hide-cont');

      $('.a-pic-title--benefit').removeClass('show-title').addClass('hide-title');
      $('.a-pic-title--features').removeClass('hide-title').addClass('show-title');

      $('#show-benefit').removeClass('show-btn').addClass('hide-btn');
      $('#show-features').removeClass('hide-btn').addClass('show-btn');

      $('.bf-cash').removeClass('move-cash-back').addClass('move-cash');
    },
    showfeatures: function () {
      $('.a-cont--features').removeClass('cont-down').addClass('cont-up');
      $('.a-cont--benefit').removeClass('cont-up').addClass('cont-down');

      $('.a-pic--benefit').removeClass('img-down').addClass('img-up');
      $('.a-pic--features').removeClass('img-up').addClass('img-down');

      $('.a-text--features').removeClass('show-cont').addClass('hide-cont');
      $('.a-text--benefit').removeClass('hide-cont').addClass('show-cont');

      $('.a-pic-title--benefit').removeClass('hide-title').addClass('show-title');
      $('.a-pic-title--features').removeClass('show-title').addClass('hide-title');

      $('#show-features').removeClass('show-btn').addClass('hide-btn');
      $('#show-benefit').removeClass('hide-btn').addClass('show-btn');

      $('.bf-cash').removeClass('move-cash').addClass('move-cash-back');
    },
    clear: function () {
      $('.a-cont--features').removeClass('cont-down cont-up')
      $('.a-cont--benefit').removeClass('cont-up cont-down');

      $('.a-pic--benefit').removeClass('img-down img-up');
      $('.a-pic--features').removeClass('img-up img-down');

      $('.a-text--features').removeClass('show-cont hide-cont');
      $('.a-text--benefit').removeClass('hide-cont show-cont');

      $('.a-pic-title--benefit').removeClass('hide-title show-title');
      $('.a-pic-title--features').removeClass('show-title hide-title');

      $('#show-features').removeClass('show-btn hide-btn');
      $('#show-benefit').removeClass('hide-btn show-btn');

      $('.bf-cash').removeClass('move-cash move-cash-back');
    }
  }
  animation.init();
}());