// menu
(function () {
  var menu = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $("#js-menu").on('click', function(event) {
        event.preventDefault();
        $('#menu').find('.price-form__wrapper').css({ opacity: '1' });
        $('#menu').find('.form__result').html('');
        $('.hamburger__wrapper').toggleClass('active');
        $(this).toggleClass('active');
        $('#menu').toggle();
      });
      $('a.js-menu-anchor').on('click', function() {
        var address = $(this).attr('href');
        var el = $(address);
        var target = el[0];
        $('#js-menu').removeClass('active');
        $('#menu').hide();
      });
      $(document).on('scroll', function(e) {
        if ( $(document).scrollTop() > 100 ) {
          $('.hamburger__wrapper').addClass('fixed');
        } else {
          $('.hamburger__wrapper').removeClass('fixed');
        }
      });
    }
  }
  menu.init();
}());

// slider
(function () {
  var slider = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('#js-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1
      });
      var filtered = false;
      var choisen = $('input[name=color]:checked').attr('value');
      $('#js-slider').slick('slickFilter', '.'+choisen);
      $('input[name=color]').on('click', function(event) {
        $('#js-slider').slick('slickUnfilter');
        var color = $(this).attr('value');
        $('#js-slider').slick('slickFilter', '.'+color);
      });
    }
  }
  slider.init();
}());

// count
(function () {
  var count = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('#js-count-minus').on('click', count.minus);
      $('#js-count-plus').on('click', count.plus);
    },
    minus: function () {
      var
        count = $('#js-count'),
        price = $('#js-price'),
        countVal = count.val(),
        num = parseInt(countVal) - 1;
      if ( num < 0 || num == 0 ) {
        num = 1;
      }
      count.val(num);
      if ( num < 3 ) {
        price.html('3999');
      }
      if ( num == 3 || num == 4 || num > 4 ) {
        price.html('3799');
      };
      if ( num == 5 || num > 5 ) {
        price.html('3599');
      };
    },
    plus: function () {
      var
        count = $('#js-count'),
        price = $('#js-price'),
        countVal = count.val(),
        num = parseInt(countVal) + 1;
      count.val(num);
      if ( num < 3 ) {
        price.html('3999');
      }
      if ( num == 3 || num == 4 || num > 4 ) {
        price.html('3799');
      };
      if ( num == 5 || num > 5 ) {
        price.html('3599');
      };
    }
  }
  count.init();
}());

// tabs
(function () {
  var tabs = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('#tabs').easytabs({
        updateHash: false
      });
      $('#menu-tabs-container').easytabs({
        tabs: 'ul#menu-tabs > li',
        updateHash: false,
        transitionIn: 'slideDown',
        transitionInEasing: 'linear',
        transitionOut: 'slideUp'
      });
    }
  }
  tabs.init();
}());

//smooth scroll
(function () {
  var smoothscroll = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('a.js-menu-anchor').on('click', function() {
        event.preventDefault();
        var address = $(this).attr('href');
        var el = $(address);
        var target = el[0];
        $('html, body').stop().animate({'scrollTop': target.offsetTop}, 1000);
      });
    }
  }
  smoothscroll.init();
}());

// modals
(function () {
  var modals = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('.js-show-price').on('click', function(event) {
        event.preventDefault();
        $('#modal-price').find('.price-form__wrapper').css({ opacity: '1' });
        $('#modal-price').find('.form-result').html('');
        $('#modal-price').bPopup({
          appending: false
        });
      });
      $('.js-show-delivery').on('click', function(event) {
        event.preventDefault();
        $('#modal-delivery').bPopup({
          appending: false
        });
      });
      $('.js-show-order').on('click', function(event) {
        event.preventDefault();
        $('#modal-order').find('#order-form').css({ opacity: '1' });
        $('#modal-order').find('.order-form__result').remove();
        $('#modal-order').bPopup({
          appending: false
        });
      });
      $('.js-show-info').on('click', function(e) {
        e.preventDefault();
        $('#modal-info').bPopup({
          appending: false
        });
      });
    }
  }
  modals.init();
}());

// form styling
(function () {
  var select = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('.js-select').selectize();
      $('.js-input-tel').mask('99999999999');
    }
  }
  select.init();
}());

// spectragram
(function () {
  var spectragram = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      jQuery.fn.spectragram.accessData = {
        accessToken: '1552162564.02bccc2.b10153a1d2704dfe8787c77d0bfd10f3',
        clientID: '02bccc2f57a54e2d9e5eff35879b0451'
      };
      $('#instagram').spectragram('getUserFeed',{
        query: 'tecnomed_sport',
        max: 4,
        size: 'medium'
      });
    }
  }
  spectragram.init();
}());