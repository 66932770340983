// validation
(function () {
  var validate = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('button[type=submit]').on('click', validate.validation);
    },
    validation: function (e) {
      var _this = $(this);
      var form = _this.closest('form');
      var type = $(form).find('input[name=formtype]').val();
      console.log(type);
      $(form).validate({
        errorElement: 'span',
        rules: {
          name: { required: true, minlength: 3 },
          email: { required: true, email: true },
          orderemail: { email: true },
          msg: { required: true, minlength: 10 },
          tel: { required: true, minlength: 11, number: true }
        },
        messages: { name: '', email: '', msg: '', tel: '', orderemail: '' },
        submitHandler: function (form) {
          if ( type == 'contactform' ) {
            validate.sendcontact(form);
          }
          if ( type == 'orderform') {
            validate.sendorder(form);
          }
          if ( type == 'priceform' ) {
            validate.sendprice(form);
          }
        }
      });
    },
    sendcontact: function (form) {
      var data = $(form).serialize();
      $.ajax({
        url: 'forms.php',
        type: 'POST',
        dataType: 'html',
        data: data
      })
      .done(function(res) {
        var ans = $.parseJSON(res);
        if ( ans.success ) {
          $(form).find('.form__result').text('Ваше сообщение отправлено');
          $(form)[0].reset();
        } else {
          $(form).find('.form__result').addClass('error').text(ans.message);
        }
      })
      .fail(function(res) {
        $(form).find('.form__result').addClass('error').text(res);
      });
    },
    sendprice: function (form) {
      var data = $(form).serialize();
      $.ajax({
        url: 'forms.php',
        type: 'POST',
        dataType: 'html',
        data: data
      })
      .done(function(res) {
        var ans = $.parseJSON(res);
        if ( ans.success ) {
          $(form).find('.price-form__wrapper').css({ opacity: '0' });
          $(form).find('.form__result').text('Прайс-лист успешно отправлен.');
          $(form)[0].reset();
        } else {
          $(form).find('.form__result').addClass('error').text(ans.message);
        }
      })
      .fail(function(res) {
        $(form).find('.form__result').addClass('error').text(res);
      });
    },
    sendorder: function (form) {
      var orderdetails = $('#order-details').serialize();
      var formdata = $(form).serialize();
      var data = orderdetails + '&' + formdata;
      $.ajax({
        url: 'forms.php',
        type: 'POST',
        dataType: 'html',
        data: data
      })
      .done(function(res) {
        var ans = $.parseJSON(res);
        if ( ans.success ) {
          $(form).css({ opacity: '0' });
          $(form).after('<div class="order-form__result">Спасибо за заказ. <br>Мы с Вами свяжемся<div>');
          $(form)[0].reset();
        } else {
          $(form).find('.form__result').addClass('error').text(ans.message);
        }
      })
      .fail(function(res) {
        $(form).find('.form__result').addClass('error').text(res);
      });
    }
  }
  validate.init();
}());