/*
  Dependencies:
  * jQyery (https://jquery.com/)
  * Slick Carousel (https://github.com/kenwheeler/slick/)
  * EasyTabs (https://os.alfajango.com/easytabs/)
  * bPopup (http://dinbror.dk/bpopup/)
  * jQuery Validation Plugin (https://jqueryvalidation.org/)
  * masked input plugin (http://digitalbush.com/projects/masked-input-plugin/)
  * Selectize.js (http://selectize.github.io/selectize.js/)
  * fullPage.js (https://github.com/alvarotrigo/fullPage.js)
*/
new WOW().init();